import React from "react"
import TrainCarIcon from "mdi-react/TrainCarIcon"

import BusSideIcon from "mdi-react/BusSideIcon"
  

import PageBody from "../../components/page-body/pageBody"
import SEO from "../../components/seo"

import Banner from "../../components/banner/banner"
import cornerStore from "../../images/components/corner-store.jpg"
import cornerStoreBody1 from "../../images/components/corner-store-body-1.jpg"

import BorderTitle from "./../../components/border-title/borderTitle"
import ComponentNavigator from "./../../components/component-navigator/componentNavigator"
import QuoteImages from "./../../components/quote-images/quoteImages"

import "./corner-store-initiative.scss"

var CornerStoreInitiative = props => (
  <>
    <SEO title="Home" keywords={["gatsby", "application", "react"]} />
    <div className="corner-store-initiative-page">
      <Banner
        src={cornerStore}
        title="Corner-Store Initiative"
        height="32.5rem"
        heightMobile="120vw"
        backgroundPosY="center"
      />
      <PageBody className="page">
        <BorderTitle>
          Corner-Store Initiative
        </BorderTitle>
        <div className="body">
          <img className="left" src={cornerStoreBody1} alt="" /><p>A partnership with Feeding Florida to provide fresh fruits and vegetables, SNAP machines, and the Double-Your-Bucks SNAP program at four Health Zone 1 corner-stores.</p>
        </div>
        
        <div className="navigation">
        <ComponentNavigator
            link="/components-year-two/mobile-food-market-program"
            className="first"
            icon={BusSideIcon}
            title="Mobile Food Market Program"
            />
          <ComponentNavigator
            next
            link="/components-year-two/transportation-for-seniors-alliance"
            icon={TrainCarIcon}
            title="Transportation for Seniors Alliance"
          />
        </div>
      </PageBody>
    </div>
  </>
  )

export default CornerStoreInitiative
